<template>
    <section class="section-accordion" :class="color">
        <LayoutContained :class="padding">
            <ContentHeading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                :marginBottom="content ? 'mb-3' : 'mb-0'"
                :textCentered="textCentered"
            ></ContentHeading>
            <ContentText
                v-if="content"
                :html="content"
                :contentColor="contentColor"
                :textCentered="textCentered"
            ></ContentText>
            <LayoutAccordion
                :class="heading || content ? 'mt-7' : ''"
                :items="items"
                :itemColor="itemColor"
            ></LayoutAccordion>
            <slot name="custom"></slot>
        </LayoutContained>
    </section>
</template>

<script>

export default {
    name: 'SectionAccordion',
    props: {
        items: {
            required: false,
            type: Array,
            default () {
                return [];
            },
        },
        padding: {
            required: false,
            default: 'py-12 sm:py-14',
            type: String,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        itemColor: {
            required: false,
            type: String,
            default: 'bg-zinc-50 shadow shadow-zinc-300 hover:shadow-sm hover:shadow-zinc-400',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
