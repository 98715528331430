<template>
    <div class="layout-accordion">
        <dl
            class="mt-3 cursor-pointer rounded p-4"
            :class="itemColor"
            v-for="(item, index) in items"
            :key="'layout-accordion-item-' + index"
            @click="setExpanded(index)"
        >
            <dt
                class="flex w-full items-start justify-between text-left"
                :aria-controls="'faq-' + index"
                :aria-expanded="isExpanded(index)"
            >
                <span class="text-base font-semibold leading-7">
                    {{ item.heading }}
                </span>
                <span class="ml-6 flex h-7 items-center">
                    <component :is="isExpanded(index) ? 'IconMinus' : 'IconPlus'"></component>
                </span>
            </dt>
            <dd
                :id="'faq-' + index"
                class="item-content mt-2 pr-12"
                :class="isExpanded(index) ? '' : 'hidden'"
            >
                <component :is="itemContent(item.content)"></component>
            </dd>
        </dl>
    </div>
</template>

<script>
export default {
    name: 'LayoutAccordion',
    props: {
        items: {
            required: false,
            type: Array,
            default () {
                return [];
            },
        },
        itemColor: {
            required: false,
            type: String,
            default: 'bg-white',
        },
    },
    data () {
        return {
            expanded: null,
        };
    },
    methods: {
        itemContent (content) {
            return {
                template: `<div class="text-sm leading-7 sm:text-base">${content}</div>`,
            };
        },
        setExpanded (index) {
            this.expanded = index === this.expanded ? null : index;
        },
        isExpanded (index) {
            return this.expanded === index;
        },
    },
};
</script>
